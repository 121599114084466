@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');


:root {
   
    --main-white: #ffffff;
    --dash-menu-hover: #152047;
    --main-light-gray: #888888;
    --dark-black: #14141d;
    --main-font: 'Inter';
    --light-gray: #7E7C86;
    --dark-blue: #262430;
    --dark-blue-hover: #201e2b;
    --main-color: #C7B299;
    --main-color-hover: #a8947a;
    --dash-bg: #1C1A24;
  }
  html {
    scroll-behavior: smooth;
    font-size: 1rem;
  }
  body {
    font-family: var(--main-font);
    background: #14141b;
  
    z-index: 99;
  }
   
  a {
    text-decoration: none !important;
  }
  .nav-link {
    font-family: var(--main-font-u-light-light);
  
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2.0625rem;
    color: var(--main-light-gray) !important ;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .nav-link:hover {
    color: var(--main-white) !important ;
    transition: 0.2s all ease-in-out;
  }
  .active .nav-link {
    font-weight: 700;
    color: var(--main-white) !important;
  }
     
  .row > * {
    margin-bottom: 1.875rem;
  }
  
  /* dashboard */
  .logo img{
    width:3.125rem;
  }
  .clr-grey{
    color: var(--light-gray);
    font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.75rem;
  }
  .top-part .new-topbar{
    border-radius: 0rem !important;
    background-color: var(--dark-blue) !important;
    padding: 1.25rem 1.875rem;
    display: flex;
    justify-content: space-between;
  }
  .right-top{
    display: flex;
    align-items: center;
  }
  .bell-icon, .profile {
    cursor: pointer;
  }
  .left-top{
    display: flex;
    align-items: center;
  }
  .second-part{
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .second-top{
    display: flex;
    justify-content: space-between;
  }
  .s-left{
    display: flex;
    align-items: center;
  }
  .welcome{
    color: var(--main-light-gray);
    font-size: 1rem;
    font-weight: 600;
  }
  .p-name{
    font-size: 1.25rem;
    color: var(--main-white);
  }
  .box-grey{
    background: var(--dark-blue);
    padding: 0.9375rem;
    display: flex;
    border-radius: 0.25rem;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
  }
  .box-grey:hover{
    background: var(--dark-blue-hover);
    
  }
  .s-right{
    display: flex;
  }
  .box-grey img{
    width: 1.875rem;
    height: 1.875rem;
  }
  .box-grey .p-name{
    font-size: 0.875rem;
    font-weight: 600;
  }
  .box-grey .welcome{
    font-size: 0.75rem;
  }
  .dash-btn{
    background: var(--main-color) ;
    padding: 0.625rem 1.875rem;
    color: white!important;
    border-radius: 0.25rem;
    text-align: center;
  }
  .dash-btn:hover{
    background: var(--main-color-hover) ;
    transition: 0.2s all ease-in-out;
    color: white;
    
  }
  .four-box-part .box-grey{
    display: block;
    cursor: unset;
  }
  .four-box-part .welcome{
    padding-top: 0.625rem;
    font-size: 0.875rem;
  }
  .four-box-part .p-name{
    font-size: 1rem;
  }
  .four-box-part{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.625rem;
  }
  .form-group input , .form-group select , .form-group option {
    background: var(--dark-blue);
    border: none;
    outline: none;
    color: var(--main-white);
    border-radius: 0.25rem;
  }
  .form-group input:focus:focus-within , .form-group select:focus-within:focus , .form-group option:focus:focus-within {
    background: var(--dark-blue-hover);
    border: none;
    outline: none;
    color: var(--main-white);
    box-shadow: none !important;
  
     
  }
  .main-plus-minus{
    margin-left: 0.3125rem;
  }
  .main-plus-minus button{
    background-color: var(--dark-blue) !important ;
    border-radius: 0rem;
    border: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    }
    .main-plus-minus button:active:focus{
    background-color: var(--dark-blue-hover) !important;
    
    border: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    }
  .botttom-btn .copy-btn{
    background: var(--dark-blue);
  }
  .botttom-btn .copy-btn:hover{
    background: var(--dark-blue-hover);
  }
  .slider.round {
    border-radius: 2.125rem;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  .two-part{
    display: grid;
    grid-template-columns: 40% 1fr;
    gap: 1.5625rem;
  }
  .head-with-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ip{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0.9375rem;
    background: var(--dark-blue);
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
  }
  .ip img{
    width: 1.25rem;
    cursor: pointer;
  }
  .ip img:hover{
    transform: scale(1.1);
    transition: 0.1s all ease-in-out;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background: #c7b29956;
    color: var(--main-white) !important;
    padding: 0.1875rem 0rem;
    border-radius: 0.5rem;
  }
  .nav-pills .nav-link{
    
    padding: 0.1875rem 0rem;
  }
  .switch-part{
    display: flex;
    align-items: center;
    gap: 0.625rem;
    flex-wrap: wrap;
  }
  
  
  
  
  .dash-mene-img {
    display: block;
    margin-right: 0.625rem;
    width: 1.25rem;
    height: 1.25rem;
  }
  .list-group-item {
    text-align: center;
    background-color: unset;
    border: none;
    display: flex;
    align-items: center;
    justify-content: start;
    color: var(--main-white);
    margin-bottom: 0.9375rem;
    font-family: var(--main-font-u-light);
    transition: 0.3s all ease;
    padding: .75rem 1.20rem;
  }
  .list-group-item:hover,
  .list-group-item.active , .list-group-item-action:active , .list-group-item-action:focus{
    text-align: center;
    background-color: var(--dark-blue);
    transition: 0.2s all ease-in-out;
    border: none;
    opacity: 1;
    border-radius: 0.375rem;
    color: var(--main-white);
    
  }
  .list-groups span {
    color: var(--main-white);
    text-transform: uppercase;
    font-family: var(--main-font-light);
    font-size: 0.9375rem;
  }
  #sidebar-wrapper {
    background: #1C1A24;
  }
   
   
   
  
  .dash-main-bg {
    background: #0f0f15;
  }
  
  .dash-bg {
    color: var(--main-white);
    background-color:var(--dash-bg);
  
    padding: 1.8rem 1.8rem;
    border-radius: 1rem;
  }
  .plan-usage {
    display: grid;
    gap: 2.625rem;
    grid-template-columns: 1fr 1fr;
  }
   
  .dash-bg h2 {
    font-size: 1.1rem;
    margin-bottom: 0.9375rem;
    font-weight: 500;
  }
   
  
  .ip-details p {
    margin: 0.3125rem 0rem;
    color: var(--light-blue);
  }
  
  .my-ip input:focus {
    outline: none;
  }
  .proxy-number p {
    font-size: 0.75rem;
    color: var(--main-white);
  }
   
  .table th {
    font-family: var(--main-font-light);
    font-size: 0.8125rem;
    text-transform: uppercase;
    border: none;
    font-weight: 400;
    color: var(--light-gray);
  }
  .table td {
    border: none;
    vertical-align: middle;
    text-transform: uppercase;
    font-family: var(--main-font-light);
  }
  .table {
    color: var(--main-white);
    border: none;
    text-align: center;
  }
  td:first-child,
  th:first-child {
    text-align: left;
  }
  .round-input {
    border-radius: 1.875rem;
  }
  .purchase-btn {
    font-family: var(--main-font-light) !important;
    font-weight: bold;
  }
  .plan-second {
    margin-top: 1.875rem;
  }
  .auto-topup {
    display: flex;
    align-items: center;
  }
  .plan-plus-minus .down_count {
    border-radius: 3.125rem 0rem 0rem 3.125rem;
  }
  .plan-plus-minus .up_count {
    border-radius: 0rem 3.125rem 3.125rem 0rem;
  }
  .select-head {
    font-family: var(--main-font-light);
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.8125rem;
    margin-top: 1.25rem;
  }
  .dash-table-wrapper {
    margin: 0rem;
    display: flex;
    height: auto;
    align-items: center;
    padding: 1.875rem 6.25rem;
  }
   
  /* dashboard-new */
  .dash-m-btn {
    background: var(--dash-btn);
    font-family: var(--main-font);
    border-radius: 0rem;
    padding: 0.625rem 1.5625rem;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .dash-m-btn:hover {
    background: var(--dash-btn-hover);
  }
  .dash-hr {
    border-top: 0.0625rem solid rgb(156 156 156);
  }
  .ip-details.proxy-number {
    background: none;
    height: 17.5rem;
    overflow-y: scroll;
  
    padding: 0.9375rem 1.5625rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid rgb(59, 59, 59);
  }
  .my-ip input {
    text-align: left;
    background: var(--dark-blue);
    font-size: 0.9375rem;
    border: none;
    color: var(--main-white);
    font-family: var(--main-font-light);
    border-radius: 1.875rem;
    padding: 0.625rem 0.9375rem;
  }
  .my-ip input::placeholder {
    color: var(--main-white);
  }
  .authenticate {
    background: var(--light-blue);
    border-radius: 0.25rem;
    padding: 0.625rem 0.5rem;
    font-size: 1rem;
    font-family: var(--main-font);
  }
  .authenticate:hover {
    background: var(--light-blue-hover);
  }
  .ip-section {
    display: flex;
  }
  .ip-section .ip-div {
    padding: 0.5rem 0.75rem;
    background: var(--dark-blue);
    margin-right: 0.5rem;
    position: relative;
  }
  .ip-button {
    background: var(--dark-blue);
    border-radius: 50%;
    width: 1.125rem;
    height: 1.125rem;
    color: white;
    position: absolute;
    top: -0.625rem;
    right: 0.3125rem;
    font-size: 0.625rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dash-select {
    background: var(--dark-blue);
    display: inline-block;
    margin-top: 0.9375rem;
    border-radius: 6.25rem;
  }
  .dash-select .nav-pills .nav-link.active {
    border-radius: 3.125rem;
    color: var(--main-white) !important;
  }
  .dash-select .nav-pills .nav-link {
    min-width: 6.25rem;
  }
  .warning-modal input[type='checkbox']{
    pointer-events: unset;
  }
  input[type='checkbox']:after {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.9375rem;
    top: -0.1875rem;
    left: -0.125rem;
    position: relative;
    background-color: #303030;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0.125rem solid rgba(255, 255, 255, 0.021);
  }
  input[type='checkbox']:checked:after {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.9375rem;
    top: -0.1875rem;
    left: -0.125rem;
    position: relative;
    background-color: #2fff97;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 0.125rem solid #2fff97;
  }
  .table tr:hover {
    background: var(--dark-blue);
  }
    
   
   
   
  /* responsive css */
   
  @media only screen and (max-width: 1440px) {
    html{
      font-size: 0.875rem;
    }
    .plan-usage {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px) {
    html{
      font-size: 0.75rem;
    }
    
   
  }
  @media only screen and (max-width: 1024px) {
    .two-part{
      grid-template-columns: 1fr;
    }
    .achv-box img {
      width: 3.75rem;
      height: 3.75rem;
    }
    .achv-text {
      font-size: 1.5rem;
    }
    .plan-usage {
      grid-template-columns: 1fr;
    }
  
    
  }
  @media only screen and (max-width: 768px) {
    html{
      font-size: 0.875rem;
    }
   
  }
  @media only screen and (max-width: 580px) {
    .second-top {
      
      flex-wrap: wrap;
    }
    .s-right{
      width: 100%;
    }
    .s-right .box-grey{
      width: 100%;
      margin-top: 0.9375rem;
    }
   
    .dash-mene-img {
      display: block;
  
      width: 1.5625rem;
      height: 1.5625rem;
    }
    .setting-logout .list-group-item img {
      width: 1.5625rem;
      height: 1.5625rem;
    }
    .botttom-btn{
      flex-wrap: wrap;
      flex-grow: 100%;
    }
    .botttom-btn .down-bg-part{
      margin-top: 0.625rem !important;
    }
  }
  @media only screen and (max-width: 360px) {
    #page-content-wrapper .container-fluid {
      padding: 0.9375rem;
    }
    .dash-bg {
      padding: 1.875rem 0.625rem;
    }
    .dash-bg h2 {
      font-size: 1.125rem;
      margin-bottom: 0.9375rem;
      font-weight: 500;
    }
    .modal-dialog .modal-content {
      background: #010b22;
      color: var(--main-white);
      border-radius: 2.1875rem;
      padding: 2.8125rem 0.3125rem;
    }
  }
  
  /* scroll bar */
  /* width */
  ::-webkit-scrollbar {
    width: 0.3125rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--dark-blue);
  }
  /* width */
  .table-responsive::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
    overflow-y: scroll;
  }
  
   
  
  /* loader */
  .preloader {
    position: fixed;
    left: 0rem;
    top: 0rem;
    width: 100%;
    height: 100%;
    z-index: 999999;
  
    /* background-size: 80px; */
  }
  
   
  
  /* sidebar */
  /* The side navigation menu */
  #wrapper {
    overflow-x: hidden;
  }
  
  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -14.5rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
  }
  
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
  
  #sidebar-wrapper .list-group {
    width: 14.5rem;
    padding: 0rem 0.8rem;
  }
   
  
  #page-content-wrapper {
    min-width: 100vw;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }
  .menu-margin {
    margin-top: 2.5rem !important;
  }
  .dash-col {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    align-items: center;
  }
  .balance-btn {
    margin-right: 0.9375rem;
    background: #ffffff;
    padding: 0.625rem 0.8125rem;
    border-radius: 0.625rem;
    margin-left: auto;
  }
  .balance-btn span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #7882a2;
  }
  .btn-icon {
    padding-right: 0.625rem;
  }
  .btn-icon svg {
    padding-right: 0.625rem;
  }
  .d-flex.toggled #page-content-wrapper {
    margin-right: -12.5rem;
    transition: 0.2s all ease-in-out;
  }
  #page-content-wrapper .border-bottom {
    border-bottom: 0.0625rem solid #404040 !important;
  }
  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }
  }
  
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .active-menu {
    font-weight: 600;
  }
  
  /* switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }
  
  .switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d1d1d1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #c7b299;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 0.0625rem #2196f3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  
  /*------ ADDED CSS ---------*/
  .on {
    display: none;
  }
  
  .on,
  .off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 0.9375rem;
  }
  .off {
    padding-left: 0.625rem;
  }
  .on {
    padding-right: 0.625rem;
  }
  
  input:checked + .slider .on {
    display: block;
  }
  
  input:checked + .slider .off {
    display: none;
  }
  
  /*--------- END --------*/
   