/* Header CSS Start */
header {
  background-color: var(--darker);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 72px;
}

header .navbar {
  padding: 5px 15px;
}

header .navbar img.logo {
  max-height: 42px;
}

header .navbar .navbar-brand {
  padding: 0px;
}

.navbar-dashboard-btn {
  margin: 0px;
  background: transparent;
  border: 0px;
  padding: 5px 15px;
}

.navbar-dashboard-btn img {
  width: 24px;
  height: 24px;
}

header .navbar .navbar-toggler {
  padding: 5px 15px;
  outline: none !important;
  border: none;
}

header .navbar .navbar-toggler-icon {
  background-image: none;
  border-bottom: 2px solid var(--primary);
  width: 24px;
  height: 18px;
  position: relative;
  transition: all 300ms linear;
}

header .navbar .navbar-toggler-icon:after,
header .navbar .navbar-toggler-icon:before {
  background-color: var(--primary);
  content: "";
  width: 24px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 300ms linear;
  z-index: 2;
}

header .navbar .navbar-toggler-icon:after {
  top: 8px;
}

header .navbar-toggler.open .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

header .navbar-toggler.open .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

header .navbar-toggler.open .navbar-toggler-icon {
  border-color: transparent;
}

header .navbar .nav-item {
  margin: 0px;
  padding: 0px 12px;
}

header .navbar .nav-item.nav-dashboard,
header .navbar .nav-item.nav-discord,
header .navbar .nav-item.nav-logout,
header .navbar .nav-item.nav-login {
  position: absolute;
  top: 16px;
}

header .navbar .nav-link.btn-dashboard,
header .navbar .nav-link.btn-discord,
header .navbar .nav-link.btn-logout,
header .navbar .nav-link.btn-login {
  border-radius: 10px;
  border: 2px solid var(--secondary);
  color: var(--primary) !important;
  padding: 6px 8px !important;
  font-weight: 500;
}

header .navbar .nav-item.nav-discord {
  right: 78px;
}

header .navbar .nav-item.nav-dashboard {
  right: 208px;
}

header .navbar .nav-item.nav-logout,
header .navbar .nav-item.nav-login {
  right: 0px;
}

header .navbar .nav-link {
  color: var(--primary) !important;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  padding: 24px 0px !important;
  position: relative;
  transition: color 200ms ease;
}

header .navbar .nav-link:hover {
  color: var(--primary) !important;
}

header .navbar .nav-link.active {
  border-bottom: 3px solid var(--color-success);
  color: var(--color-success) !important;
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  header .navbar-nav {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}

@media screen and (max-width: 991px) {
  header .navbar .nav-item {
    padding: 0px 6px;
  }
}

@media screen and (max-width: 767px) {
  header {
    height: 66px;
  }
  header .navbar {
    padding: 12px 0px;
  }

  header .navbar .navbar-brand {
    padding: 0px 10px;
  }

  header .navbar .navbar-nav {
    margin: 10px 0px;
    padding-bottom: 10px;
    align-items: flex-start !important;
  }

  header .navbar .nav-item {
    padding: 4px 0px;
  }

  header .navbar .nav-link {
    padding: 5px 15px !important;
  }

  header .navbar .nav-item.nav-dashboard,
  header .navbar .nav-item.nav-discord,
  header .navbar .nav-item.nav-logout,
  header .navbar .nav-item.nav-login {
    padding: 5px 15px !important;
    position: static;
    right: auto;
  }
}
/* Header CSS End */
