/* Footer CSS Start */
footer {
  background-color: var(--darker);
  font-size: 1rem;
  font-weight: 400;
  padding: 30px 0px;
}

footer .logo {
  max-height: 42px;
  margin-top: -10px;
}

footer .title {
  font-size: 1.25rem;
  margin: 16px 16px 16px 16px;
}

footer .nav-link {
  color: var(--primary) !important;
  padding: 0px 10px;
  margin: 0px;
}

footer .navigation-title {
  font-weight: 600;
  padding: 10px 10px;
  color: var(--light);
}

footer .policy-links .nav-link {
  color: #8a8a8a !important;
}

footer .copyright {
  color: var(--light);
  font-size: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  footer {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 30px 0px;
  }

  footer .navigation-title {
    padding: 15px 10px 10px;
  }

  footer .policy-links .nav-link {
    padding: 0px 5px;
    letter-spacing: -0.5px;
  }

  footer .policy-links .nav-link:first-child {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  footer {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 24px 0px;
  }

  footer .navigation-title {
    padding: 5px 10px 10px;
  }

  footer .policy-links {
    font-size: 0.75rem;
    margin: 0px !important;
  }

  footer .copyright {
    font-size: 0.875rem;
    padding: 0px 10px;
  }
}
/* Footer CSS End */
