.animation-paused * {
    animation-play-state: paused !important;
}


/* Page Loader CSS */

.preloader {
    background: var(--secondary) url("../img/preloader.svg") center no-repeat 80px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
}

.preloader-container {
    align-items: center;
    background: var(--darker) url("../img/logo.png") no-repeat center/50px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.preloader-loading-screen {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 1px solid var(--secondary);
    border-right: none;
    border-bottom: none;
    animation-name: spin, fade;
    animation-duration: 3s, 3s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1), linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1170deg);
    }
}

@keyframes fade {
    0%,
    100%,
    70% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}


/* Toast CSS */

#toast-container>div {
    opacity: 1 !important;
}

.toast-success {
    background-color: var(--success) !important;
}

.toast-error {
    background-color: var(--error) !important;
}

.toast-info {
    background-color: var(--info) !important;
}

.toast .toast-close-button {
    box-shadow: none;
    font-size: 1.5rem;
}

.toast .toast-title {
    font-size: 1rem;
    font-weight: 700;
}

.toast .toast-message {
    margin-top: 4px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: 0.5px;
}


/* checkoutModal CSS */

.checkoutModal {
    background: var(--darker);
    color: var(--primary);
    padding-right: 0px !important;
    text-align: center;
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    transform: none !important;
    z-index: 99999;
}

.checkoutModal .modal-content {
    background: var(--dark);
    color: var(--primary);
    padding: 125px 0px;
    width: inherit;
    height: 100%;
}

.checkoutModal .modal-header,
.checkoutModal .modal-body,
.checkoutModal .modal-footer {
    border: none;
    display: block;
    max-width: 425px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 15px;
}

.checkoutModal .modal-header .close {
    color: var(--secondary);
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 48px !important;
    font-weight: 400;
    opacity: 0.75;
    outline: none;
    text-shadow: 0.5px 0.5px 0px var(--dark);
}

.checkoutModal .modal-header .close:hover {
    transform: scale(1.1);
}

.checkoutModal .modal-header img {
    max-height: 48px;
}

.checkoutModal .modal-header .modal-title .title {
    font-size: 1.25rem;
    font-weight: 500;
    padding: 5px 0px;
}

.checkoutModal .modal-body {
    flex: none;
}

.checkoutModal .modal-body #applyCouponCodeBtn {
    font-weight: 500;
    padding: 0px 12px;
}

.checkoutModal .modal-footer #checkoutPayBtn {
    font-weight: 500;
}

.checkoutModal .modal-body #applyCouponCodeBtn:disabled,
.checkoutModal .modal-footer #checkoutPayBtn:disabled {
    opacity: 0.9;
    cursor: not-allowed;
}

.checkoutModal .modal-body #paymentInfo>div {
    font-size: 1rem;
    line-height: 1.45;
}

.checkoutModal .modal-footer #checkoutTemrs {
    font-size: 0.75rem;
    letter-spacing: -0.25px;
    margin-top: 12px;
}

.checkoutModal .spinner-grow {
    background-color: var(--primary);
    border-radius: 50%;
    color: var(--primary);
    display: inline-block;
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
    opacity: 0.9;
    animation: spinner-grow 0.75s linear infinite;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
    }
}

.authtype-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 1px solid var(--secondary);
    border-radius: 50px;
    /* width: auto; */
    width: max-content;
    padding: 0px 4px;
}

.authtype-item {
    cursor: pointer;
    margin: 2px auto;
    padding: 2px 8px;
    border-radius: 50px;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary) !important;
}

.authtype-item.active {
    background-color: var(--secondary) !important;
    color: #ffffff !important;
    transition: 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}