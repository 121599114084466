:root {
  --primary: #ffffff;
  --secondary: #c7b299;
  --secondary-dark: #ae906c;
  --success: #00c851;
  --error: #ff4444;
  --info: #33b5e5;
  --warning: #ffbb33;
  --light: #c5c5c5;
  --gray: #828282;
  --slategrey: #484f53;
  --dark: #252a2d;
  --darker: #1c2023;
  --sans-serif: "Mulish", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px var(--darker);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 2px;
}

::-moz-selection {
  background: var(--secondary);
  text-shadow: none;
}

::selection {
  background: var(--secondary);
  text-shadow: none;
}

.text-primary {
  color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-muted {
  color: var(--gray) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-error {
  color: var(--error) !important;
}

.text-info {
  color: var(--info) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.bg-darker {
  background-color: var(--darker) !important;
}

a {
  color: var(--secondary) !important;
  text-decoration: none !important;
}

.btn,
button {
  box-shadow: none !important;
  outline: none !important;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}

.btn-theme {
  background-color: transparent !important;
  border: 2px solid var(--secondary) !important;
  border-radius: 10px;
  color: var(--primary) !important;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 16px;
  text-align: center;
}

.input-theme {
  background-color: var(--darker) !important;
  border: 1px solid var(--darker) !important;
  border-radius: 10px;
  color: var(--gray) !important;
  font-size: 0.875rem;
  font-weight: 500;
  height: auto;
  padding: 8px 12px;
  outline: none !important;
  box-shadow: none !important;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

@media screen and (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

body {
  background-color: var(--darker);
  color: var(--primary);
  font-family: var(--sans-serif);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.15;
  text-align: left;
}

body,
html {
  margin: 0px;
  padding: 0px;
}

header + main {
  padding: 30px 0px;
  margin-top: 72px;
}

@media screen and (max-width: 767px) {
  header + main {
    margin-top: 66px;
    padding: 20px 0px;
  }
}
